import { Helmet } from "react-helmet";
import HeaderSection from "../../Components/HeaderSection/HeaderSection";
import "./CareerOpenings.css";
import Text from "../../Components/Text/Text";
import Collapsible from "react-collapsible";
import ThreeColumnWrapper from "../../Components/ThreeColumnWrapper/ThreeColumnWrapper";
import PageDescription from "../../Components/PageDescription/PageDescription";
import SolutionSlider from "../../Components/SolutionSlider/SolutionSlider";
import MyButton from "../../Components/Button/Button";

const NewOpening = [
  {
    openingName: "Product Specialist – IoT",
    openingDate: "23 Jan 2025",
    openingType: "Permanent",
    openingLocation: "Karachi, Pakistan ",
    openingStatus: "open",
    openingDetails:
      "The Product Specialist - IoT will play a multifaceted role, responsible for designing IoT solutions according to customer requirements, researching new solution for existing and new use cases within the DynaSys IoT ecosystem, providing technical support to the sales team & generating comprehensive technical documentation.",
    openingResponsibilites: [
      "Develop a deep understanding of DynaSys’ IoT product portfolio, including the range of IoT applications and solutions offered.",
      "Stay updated with the latest advancements in IoT technology and industry trends to effectively develop and communicate the value proposition of our products to customers.",
      "Collaborate with customers to understand their specific requirements, challenges, and business objectives. Provide technical guidance and support to address customer inquiries, troubleshoot issues, and ensure successful implementation and adoption of our IoT solutions.",
      "Work closely with the sales teams to provide technical pre-sales support. Conduct in-depth product demonstrations and presentations to potential customers, showcasing the features, capabilities, and benefits of our IoT applications and solutions. Help the sales teams effectively position our products in the market.",
      "Develop a comprehensive understanding of the supported ecosystem of hardware and software that integrates with DynaSys’ IoT Platform. Collaborate with internal teams, including engineering and product management, to provide feedback on product enhancements, ecosystem integrations, and identify potential partnerships.",
      "Create and maintain technical documentation, including product guides, user manuals, and FAQs, to support customers and internal stakeholders. Deliver training sessions to customers and internal teams to ensure a clear understanding of our products and their functionalities.",
      "Design and implement customized IoT solutions that meet client specifications and requirements. Collaborate with the engineering team to ensure seamless integration and deployment of these solutions.",
      "Develop pricing strategies for all use cases on our IoT Platform.",
    ],
    openingRequirements: [
      "Strong technical background with at least a bachelor's degree in computer science, engineering, or a related technical field.",
      "A minimum experience of three (03) years in technical product management with at least two (02) years in IoT.",
      "Proven experience in IoT applications, solutions, or platforms is highly desirable. Familiarity with IoT protocols, cloud computing, and data analytics is a plus.",
      "Excellent verbal and written communication skills in English, with the ability to convey complex technical concepts in a clear and concise manner.",
      "Strong presentation skills to deliver engaging product demonstrations and presentations",
      "Demonstrated ability to understand customer needs and provide effective solutions.",
      "Analytical mindset with the ability to troubleshoot and resolve technical issues independently.",
      "Strong problem-solving skills to identify customer pain points and propose appropriate solutions.",
      "Collaborative mindset with the ability to work effectively within cross-functional teams.",
      "Proven experience in working closely with sales teams and other stakeholders to achieve common goals.",
      "Comfortable working in a fast-paced and dynamic environment. Willingness to learn and adapt to new technologies and industry trends.",
    ],
  },
  {
    openingName: "UI/UX Designer",
    openingDate: "13 Dec 2024",
    openingType: "Permanent",
    openingLocation: "Islamabad or Karachi, Pakistan ",
    openingStatus: "closed",
    openingDetails:
      "The UI/UX Designer at DynaSys Networks is responsible for creating seamless and engaging user experiences across our IoT platform. This role requires a blend of creativity and technical expertise to design intuitive interfaces that ensure ease of use while meeting the needs of our diverse user base. As part of the design team, the UI/UX Designer will work closely with product managers, engineers, and other stakeholders to deliver user-centred designs that align with DynaSys’ vision and goals.",
    openingResponsibilites: [
      "Design wireframes, user flows, and interactive prototypes to visualize the user experience.",
      "Create low-fidelity and high-fidelity mock-ups for various platforms, including web and mobile.",
      "Collaborate with product managers and engineers to ensure design feasibility and alignment with platform capabilities.",
      "Develop visually appealing and cohesive user interfaces that adhere to brand guidelines and best design practices.",
      "Focus on consistency in design across the platform, including colours, typography, and component usage.",
      "	Ensure that the design is responsive and adaptable to different screen sizes and devices.",
      "Conduct usability testing with real users to validate design choices and identify areas for improvement.",
      "Analyse feedback from user testing to iterate and refine designs.",
      "Continuously improve the UI/UX by integrating feedback and data from user interactions.",
      "Work closely with the product team to understand user requirements and technical constraints.",
      "Collaborate with developers to ensure design implementation aligns with vision and functionality.",
      "Communicate design concepts clearly to stakeholders and provide justification for design decisions.",
      "Contribute to and maintain a consistent design system that ensures uniformity across all products.",
      "Document design specifications, guidelines, and assets for handoff to development teams.",
      "Maintain organized design files and assets for future reference and updates.",
      "Stay up to date with the latest UI/UX trends, design tools, and technologies.",
      "Apply new methodologies and techniques to improve user experience and interface design.",
      "Experiment with design ideas, features, and approaches to keep the product visually and functionally innovative.",
    ],
    openingRequirements: [
      "Bachelor’s degree in Graphic design, Interaction Design, Human-Computer Interaction, or a related field.",
      "3+ years of experience in UI/UX design, preferably in web and mobile applications.",
      "A strong portfolio showcasing past UI/UX projects, wireframes, and prototypes.",
      "Experience with design and prototyping tools such as Sketch, Figma, Adobe XD, or similar.",
      "Familiarity with front-end development (HTML, CSS, JavaScript) is a plus.",
      "Strong understanding of design principles, typography, colour theory, and layout.",
      "Expertise in user-centred design principles and best practices.",
      "Ability to translate complex user needs into simple, elegant design solutions.",
      "Familiarity with popular design systems and guidelines such as Material Design and Human Interface Guidelines to create cohesive and consistent user interfaces.",
      "Experience conducting user research, interviews, and usability testing.",
      "Ability to analyse data and feedback to drive design decisions.",
      "Strong communication skills to effectively present design ideas to stakeholders.",
      "Collaborative mindset, able to work closely with cross-functional teams.",
      "Detail-oriented with a passion for creating exceptional user experiences.",
      "Creative and innovative thinker with a strong design aesthetic.",
      "User-focused, with a deep understanding of the needs and behaviours of target audiences.",
      "A proactive and self-motivated individual who thrives in a fast-paced environment.",
      "Adaptable and open to feedback, with a continuous learning mindset.",
      "Strong problem-solving skills and attention to detail.",
    ],
  },
  {
    openingName: "Product Specialist – IoT",
    openingDate: "14 May 2024",
    openingType: "Permanent",
    openingLocation: "Islamabad or Karachi, Pakistan ",
    openingStatus: "closed",
    openingDetails:
      "The Product Specialist - IoT will play a multifaceted role, responsible for designing IoT solutions according to customer requirements, researching new solution for existing and new use cases within the DynaSys IoT ecosystem, providing technical support to the sales team & generating comprehensive technical documentation.",
    openingResponsibilites: [
      "Develop a deep understanding of DynaSys’ IoT product portfolio, including the range of IoT applications and solutions offered.",
      "Stay updated with the latest advancements in IoT technology and industry trends to effectively develop and communicate the value proposition of our products to customers.",
      "Collaborate with customers to understand their specific requirements, challenges, and business objectives. Provide technical guidance and support to address customer inquiries, troubleshoot issues, and ensure successful implementation and adoption of our IoT solutions.",
      "Work closely with the sales teams to provide technical pre-sales support. Conduct in-depth product demonstrations and presentations to potential customers, showcasing the features, capabilities, and benefits of our IoT applications and solutions. Help the sales teams effectively position our products in the market.",
      "Develop a comprehensive understanding of the supported ecosystem of hardware and software that integrates with DynaSys’ IoT Platform. Collaborate with internal teams, including engineering and product management, to provide feedback on product enhancements, ecosystem integrations, and identify potential partnerships.",
      "Create and maintain technical documentation, including product guides, user manuals, and FAQs, to support customers and internal stakeholders. Deliver training sessions to customers and internal teams to ensure a clear understanding of our products and their functionalities.",
      "Design and implement customized IoT solutions that meet client specifications and requirements. Collaborate with the engineering team to ensure seamless integration and deployment of these solutions.",
      "Develop pricing strategies for all use cases on our IoT Platform.",
    ],
    openingRequirements: [
      "Strong technical background with at least a bachelor's degree in computer science, engineering, or a related technical field.",
      "A minimum experience of five (05) years in technical product management with at least three (03) years in IoT.",
      "Proven experience in IoT applications, solutions, or platforms is highly desirable. Familiarity with IoT protocols, cloud computing, and data analytics is a plus.",
      "Excellent verbal and written communication skills in English, with the ability to convey complex technical concepts in a clear and concise manner.",
      "Strong presentation skills to deliver engaging product demonstrations and presentations.",
      "Demonstrated ability to understand customer needs and provide effective solutions.",
      "Analytical mindset with the ability to troubleshoot and resolve technical issues independently.",
      "Strong problem-solving skills to identify customer pain points and propose appropriate solutions.",
      "Collaborative mindset with the ability to work effectively within cross-functional teams.",
      "Proven experience in working closely with sales teams and other stakeholders to achieve common goals.",
      "Comfortable working in a fast-paced and dynamic environment. Willingness to learn and adapt to new technologies and industry trends.",
    ],
  },
];

export default function CareerOpenings() {
  return (
    <>
      <div className="CareerOpenings">
        <Helmet>
          <title>Available Positions</title>
        </Helmet>
        <HeaderSection
          BannerText=""
          Src="/Images/careerbanner.png"
          text="Careers <span>/</span>"
          text1="Available Positions"
          RouteLink="/career"
          RouteLink1="/"
          Color1="#6C6C6C"
          Color2="black"
        />
        <PageDescription
          text="Available<br>Positions"
          text1="At DynaSys Networks, our current vacancies offer compelling opportunities for exceptional individuals to join our dynamic team. We seek passionate professionals who excel in innovative, collaborative environments. Join us in shaping technology's future."
        />
        {NewOpening.map((item) => (
          <>
            <div className="NewOpeningsTopper">
              <div className="NewOpeningName">
                <div>
                  <Text
                    text={item.openingName}
                    FontSize={"2.6rem"}
                    FontWeight={"700"}
                    Color={"#3D3D3D"}
                  />
                  <Text
                    text={item.openingDate}
                    FontSize={"1.6rem"}
                    FontWeight={"500"}
                    Color={"#3D3D3D"}
                  />
                  <div
                    className="EmailApply"
                    FontSize={"1.6rem"}
                    FontWeight={"500"}
                    Color={"#3D3D3D"}
                  >
                    <Text
                      text={"Apply at:"}
                      FontSize={"1.6rem"}
                      FontWeight={"500"}
                      Color={"#3D3D3D"}
                      Margin={"0px 0.5rem 0rem 0rem"}
                    />
                    <MyButton
                      FontSize={"1.6rem"}
                      FontWeight={"500"}
                      Color={"#3D3D3D"}
                      target={"blank"}
                      text={"hr@dynasysnetworks.com"}
                      RouteLink={
                        "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hr@dynasysnetworks.com&su=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory"
                      }
                    />
                  </div>
                </div>

                <div className="NewOpeningButtons">
                  <Text
                    text={item.openingType}
                    BgColor={"#212121"}
                    Color={"white"}
                    Padding={"6px 3rem"}
                    Radius={"25px"}
                  />
                  <Text
                    text={item.openingLocation}
                    Border={"1px solid black"}
                    Radius={"25px"}
                    Padding={"6px 3rem"}
                  />
                </div>
              </div>
              <Text Margin={"5rem 0rem"} text={item.openingDetails} />
              <div className="OpeningRequirements">
                <Collapsible trigger="Key Responsibilities:">
                  {item.openingResponsibilites.map((responsibility, idx) => {
                    // Check for headings and apply specific styling
                    const isHeading = responsibility.endsWith(":");
                    return (
                      <div
                        className="JobRequirqment"
                        key={idx}
                        style={{
                          background: isHeading
                            ? "none"
                            : "url(/Images/careerPageArrow.png)",
                        }}
                      >
                        <Text
                          text={responsibility}
                          FontSize={isHeading ? "1.8rem" : "1.5rem"} // Larger font for headings
                          FontWeight={isHeading ? "700" : "400"} // Bold font for headings
                        />
                      </div>
                    );
                  })}
                </Collapsible>
                <Collapsible trigger="Position Requirements:">
                  {item.openingRequirements.map((responsibility, idx) => {
                    // Check for headings and apply specific styling
                    const isHeading = responsibility.endsWith(":");
                    return (
                      <div
                        className="JobRequirqment"
                        key={idx}
                        style={{
                          background: isHeading
                            ? "none"
                            : "url(/Images/careerPageArrow.png)",
                        }}
                      >
                        <Text
                          text={responsibility}
                          FontSize={isHeading ? "1.8rem" : "1.5rem"} // Larger font for headings
                          FontWeight={isHeading ? "700" : "400"} // Bold font for headings
                          className={isHeading ? "Collapsible-heading" : ""} // Apply specific class
                        />
                      </div>
                    );
                  })}
                </Collapsible>
                <div className="Desktop">
                  {item.openingStatus === "closed" ? (
                    <button disabled>
                      <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hr@dynasysnetworks.com&su=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                        Position Closed
                      </a>
                    </button>
                  ) : (
                    <button>
                      <a
                        target="blank"
                        href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hr@dynasysnetworks.com&su=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory"
                      >
                        Apply
                      </a>
                    </button>
                  )}
                </div>
                <div className="Mobile">
                  {item.openingStatus ? (
                    <button disabled>
                      <a href="mailto:hr@dynasysnetworks.com?subject=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                        Position Closed
                      </a>
                    </button>
                  ) : (
                    <button>
                      <a href="mailto:hr@dynasysnetworks.com?subject=Job%20Application%20(Please%20Mention%20the%20Position%20you%20are%20applying%20for)&body=Dear%20HR,%0A%0ACV%20attachment%20is%20compulsory">
                        Apply
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
        {/* {applyForm ? (
          <div className="ApplyFormModal">
            <div className="ApplyForm">
            <Text text={"Welcome to DynaSys Networks"} FontSize={"1.4rem"} />
            <form action="Post">
              <label for="name">Name:</label>
              <input type="text" />
              <label for="email">Email:</label>
              <input type="email" />
              <label for="contact">Contact Number:</label>
              <input type="number" />
              <label for="resume">Resume:</label>
              <input type="file" />

            </form>
            </div>
          </div>
        ) : (
          ""
        )} */}

        <ThreeColumnWrapper
          Col1Text={
            "<span>Our Vision</span> <br> <br> We strive to establish ourselves as a preeminent force in technological innovation worldwide.<br><br>Empower organizations and individuals globally in an interconnected future.<br><br>Develop advanced solutions to catalyze positive transformations, foster sustainable growth and enrich worldwide quality of life."
          }
          Col2Text={
            "<span>Our Mission</span> <br> <br> Deliver innovative technology solutions that surpass customer expectations, drive business success, and create societal value.<br><br>Push the boundaries of what's possible, leveraging expertise in IoT, space technology, cybersecurity, and networking to solve complex challenges and shape technology's future."
          }
        />
        {/* Solution Slider */}
        <div className="SolutionSlider">
          <SolutionSlider />
        </div>
      </div>
      <div className="borderSec">
        <div className="border"></div>
      </div>
    </>
  );
}
